import { FirmwareSchema } from 'api/models/FirmwareSchema';

type Validator = {
  rule: string;
  error: string;
};

export const deviceModelOptions = [
  {
    label: 'VERSA1',
    value: 'VERSA1',
  },
  {
    label: 'VERSA1.1',
    value: 'VERSA1.1',
  },
  {
    label: 'VERSA2',
    value: 'VERSA2',
  },
  {
    label: 'VERSA2A',
    value: 'VERSA2A',
  },
  {
    label: 'VERSA_GATEWAY1',
    value: 'VERSA_GATEWAY1',
  },
  {
    label: 'VERSA_GATEWAY2',
    value: 'VERSA_GATEWAY2',
  },
];

export const isInt = { rule: 'isInt', error: 'It should contain an integer' };
export const minNumber = (number: number) => ({
  rule: `minNumber:${number}`,
  error: `It should be greater than or equal to ${number}`,
});
export const maxNumber = (number: number) => ({
  rule: `maxNumber:${number}`,
  error: `It should be less than or equal to ${number}`,
});

export const generalSettingsFields = [
  {
    name: 'heartbeat',
    label: 'Heartbeat [s]',
    validators: [isInt, minNumber(0)],
  },
  {
    name: 'label_scan_time',
    label: 'Label scan time [s]',
    validators: [isInt, minNumber(0)],
  },
  {
    name: 'adsb_confirm_time',
    label: 'ADSB confirm time [s]',
    validators: [isInt, minNumber(0)],
  },
  {
    name: 'force_flight_mode_timeout',
    label: 'Force flight mode timeout [s]',
    validators: [isInt, minNumber(0)],
  },
  {
    name: 'led_brightness_level',
    label: 'LED brightness [%]',
    validators: [isInt, minNumber(0), maxNumber(100)],
  },
];

export const getValidationErrors = (validations: Validator[]): Record<string, string> => {
  const parsedValidations = validations.map((v) => {
    const rule = v.rule;
    const colonIndexOf = rule.indexOf(':');
    return { [rule.slice(0, colonIndexOf === -1 ? rule.length : colonIndexOf)]: v.error };
  });

  return parsedValidations.reduce((accumulator, currentValue) => {
    return { ...accumulator, ...currentValue };
  });
};

export const formatFirmwareOption = (firmware?: FirmwareSchema | null) => {
  if (!firmware) {
    return null;
  }

  return {
    label: `${firmware.version} (${firmware.model})${firmware.stable ? ' [stable]' : ''}`,
    value: firmware.id,
  };
};

export const parseSettingsValue = (
  value?: { value: string | number | boolean | null | undefined } | string | number | boolean | null | undefined
) => {
  if (typeof value === 'boolean') {
    return value;
  }
  if (value === '' || value === null || value === undefined) {
    return null;
  }
  const number = Number(value);
  if (!isNaN(number)) {
    return number;
  }
  if (typeof value === 'object' && value.hasOwnProperty('value')) {
    return value.value;
  }
  return value;
};
